import { bindable, customElement, inject, noView } from "aurelia-framework";
import { render, unmountComponentAtNode } from "react-dom";
import React from "react";
import ReactJson from 'react-json-view'

@noView
@inject(Element)
@customElement("json-viewer")
export default class ErrorDisplayAurelia {
    @bindable() value;
    @bindable() maxDisplayLength;
    constructor(container) {
        this.container = container;
    }

    attached() {
        this.render();
    }

    bind() {
        this.attached();
    }

    async render() {
        let parsedValue;

        try {
            parsedValue = typeof this.value === "string" && this.value.trim() ? JSON.parse(this.value) : this.value;
        } catch (error) {
            console.error("Invalid JSON:", error);
            parsedValue = { error: "Invalid JSON data" };
        }

        render(parsedValue &&
            <ReactJson
                defaultValue={parsedValue}
                name={false}
                displayObjectSize={false}
                displayDataTypes={false}
                displayComma={true}
                editable={false}
                collapseStringsAfterLength={parseInt(this.maxDisplayLength) || 15}
            />,
            this.container);
    }

    detached() {
        unmountComponentAtNode(this.container);
    }
}
